import * as React from "react"
import { /*Link,*/ graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostBlock from '../components/post-block'
import Pager from '../components/pager'

import { RefTagger } from 'react-reftagger';

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allFile.nodes
  const totalPages = Math.ceil(data.totalPosts.totalCount / 5)
  const flyerLink = `${data.site.siteMetadata.resourceServerUrl}/2024-10-06-Tim_Stevens-Gospel_Meeting.pdf`
  const flyerImage = '../../static/media/2024-10-06-Tim_Stevens-Gospel_Meeting.png'

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>
          No posts found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />
      <RefTagger bibleVersion={'ESV'} />

      <div className="mt-8"></div>

      <div className="mt-8 mb-8 border-solid border-4 border-pink-800 px-6 rounded-xl">
        <h1 className="text-pink-800 underline mt-4">Gospel Meeting at Benchley April 7-10, 2024</h1>
        <p className="mb-4 font-serif">
          The Benchley church of Christ (just north of Bryan, TX) will be holding a gospel meeting October 6-9, 2024. Tim Stevens will be presenting lessons from the gospel. Please come and join us to hear God's word! Lessons will be posted here as they are available. Click the image below to download a PDF flyer.
        </p>

        <p className="mb-4 font-serif">
          <a href={flyerLink}>
            <StaticImage src={flyerImage} className="text-center bg-cover" alt="Gospel meeting flyer"></StaticImage>
          </a>
        </p>
      </div>
      
      {posts.map(post => {
        return (
          <div className="mb-10" key={post.childMarkdownRemark.frontmatter.postId}>
            <PostBlock post={post.childMarkdownRemark} site={data.site} className="mt-10"/>
            <hr className="center-diamond"/>
          </div>
        )
      })}

      <Pager currentPage="1" totalPages={totalPages} pageLinkPrefix="/index" pageOneLink="/"/>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        audioServerUrl
        resourceServerUrl
      }
    }
    totalPosts: allFile(filter: { sourceInstanceName: { eq: "posts"} }) {
      totalCount
    }
    allFile(
      filter: { sourceInstanceName: { eq: "posts"} },
      sort: { fields: [childrenMarkdownRemark___frontmatter___postDate], order:DESC},
      limit: 5
    ) {
      nodes {
        childMarkdownRemark {
          excerpt
          fields {
            slug
          }
          html
          frontmatter {
            postId
            date(formatString: "MMMM DD, YYYY")
            lessonDate(formatString: "MMMM DD, YYYY")
            title
            description
            postType
            audio
            video
            passages
            resources
            series {
              frontmatter {
                name
                seriesId
                coverImage {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                  }
                }
              }
            }
            subSeries {
              frontmatter {
                name
                seriesId
                coverImage {
                  childImageSharp {
                    gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                  }
                }
              }
            }
            venue {
              frontmatter {
                name
                venueId
              }
            }
            presenters {
              frontmatter {
                name
                presenterId
              }
            }
            topics {
              frontmatter {
                name
                type
                topicId
              }
            }
            relatedPosts {
              frontmatter {
                postId
                date(formatString: "MMMM DD, YYYY")
                lessonDate(formatString: "MMMM DD, YYYY")
                title
                description
                postType
                audio
                video
                passages
                series {
                  frontmatter {
                    name
                    seriesId
                    coverImage {
                      childImageSharp {
                        gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                      }
                    }
                  }
                }
                subSeries {
                  frontmatter {
                    name
                    seriesId
                    coverImage {
                      childImageSharp {
                        gatsbyImageData(width: 200, layout: FIXED, placeholder: BLURRED)
                      }
                    }
                  }
                }
                venue {
                  frontmatter {
                    name
                    venueId
                  }
                }
                presenters {
                  frontmatter {
                    name
                    presenterId
                  }
                }
              }
            }           
          }
        }
      }
    }
  }
`
